.button-go-initial-step {
    font-size: 18px;
    height: 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 20px;
    width: 80%;
    color: white;
    background: linear-gradient(90deg, rgba(196, 196, 78, 1) 0%, rgba(157, 196, 81, 1) 100%);
}