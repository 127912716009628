/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    .row-input-group-company {
        width: 100%;
        padding-top: 0px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .row-input-group-company {
        width: 100%;
        padding-top: 100px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .row-input-group-company {
        width: 100%;
        padding-top: 0px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .row-input-group-company {
        width: 100%;
        padding-top: 60px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .row-input-group-company {
        width: 100%;
        padding-top: 80px;
    }
}