:root {
    --color-primary: rgb(96, 82, 163);
    --color-primary-light: rgb(230, 225, 245);
    --color-primary-blue-light: #62a2f6;
    --color-primary-pale-sky-blue: #E3F2FD;
    --color-orange: #E74D1c;
    /* Define más colores según sea necesario */
    --color-orange: #E74D1c;

    /* Colors Base */

    --color-purple-base: rgb(96, 82, 163);
    --color-purple-dark-1: rgb(76, 65, 130);
    /* 20% más oscuro */
    --color-purple-dark-2: rgb(61, 52, 104);
    /* 40% más oscuro */
    --color-purple-dark-3: rgb(49, 42, 83);
    /* 60% más oscuro */
    --color-purple-dark-4: rgb(39, 34, 66);
    --color-purple-base: rgb(96, 82, 163);
    --color-purple-light-1: rgb(117, 104, 187);
    /* 20% más claro */
    --color-purple-light-2: rgb(138, 126, 211);
    /* 40% más claro */
    --color-purple-light-3: rgb(159, 149, 235);
    /* 60% más claro */
    --color-purple-light-4: rgb(180, 171, 255);
    /* 80% más claro */

    --color-red-base: rgb(255, 0, 0);
    --color-red-dark-1: rgb(204, 0, 0);
    /* 20% más oscuro */
    --color-red-dark-2: rgb(163, 0, 0);
    /* 40% más oscuro */
    --color-red-dark-3: rgb(130, 0, 0);
    /* 60% más oscuro */
    --color-red-dark-4: rgb(104, 0, 0);
    /* 80% más oscuro */

    /* Colors Gradient Base */
    --gradient-purple: linear-gradient(16deg, rgb(96, 82, 163), rgb(50, 45, 90));
    --gradient-purple-hover: linear-gradient(16deg, rgb(128, 110, 200), rgb(70, 60, 120));
    --gradient-red: linear-gradient(16deg, rgb(211, 47, 47), rgb(139, 23, 23));
    --gradient-red-hover: linear-gradient(16deg, rgb(239, 83, 80), rgb(165, 36, 36));
    --gradient-orange: linear-gradient(16deg, #E74D1C, #C44419);
    --gradient-orange-hover: linear-gradient(16deg, #FF5A29, #D14E21);
    --gradient-green: linear-gradient(16deg, rgb(76, 175, 80), rgb(34, 139, 34));
    --gradient-green-hover: linear-gradient(16deg, rgb(129, 199, 132), rgb(46, 160, 46));
    --gradient-aqua-green: linear-gradient(16deg, rgb(179, 255, 217), rgb(128, 255, 178));
    --gradient-aqua-green-hover: linear-gradient(16deg, rgb(204, 255, 229), rgb(153, 255, 204));
    --gradient-water-blue: linear-gradient(16deg, rgb(102, 217, 255), rgb(0, 191, 255));
    --gradient-water-blue-hover: linear-gradient(16deg, rgb(153, 235, 255), rgb(51, 204, 255));
    --gradient-aqua-green-cloud: linear-gradient(16deg, rgb(230, 255, 241), rgb(210, 255, 228));
    --gradient-aqua-green-cloud-hover: linear-gradient(16deg, rgb(240, 255, 247), rgb(220, 255, 236));
    --gradient-water-blue-cloud: linear-gradient(16deg, rgb(230, 245, 255), rgb(210, 235, 255));
    --gradient-water-blue-cloud-hover: linear-gradient(16deg, rgb(240, 250, 255), rgb(220, 240, 255));
    /* Define más colores según sea necesario */
}

body {
    font-family: "Mulish", sans-serif;
    /* Otros estilos globales */
}

* {
    font-family: "Mulish", sans-serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
    font-family: 'Mulish', sans-serif;
}

.ant-btn,
.ant-input,
.ant-menu,
.ant-dropdown-link,
.ant-select-selection-item {
    font-family: 'Mulish', sans-serif;
}

.font-project {
    font-family: 'Mulish';
}

.button-general {
    padding: 8px 16px;
    background: var(--gradient-purple) !important;
    color: #fff !important;
    border: none !important;
    border-radius: 20px !important;
    cursor: pointer;
}

.button-general:hover {
    background: var(--gradient-purple-hover) !important;
}

.button-general:disabled {
    background: var(--gradient-purple-hover) !important;
    opacity: 0.7;
}