.loading-register-form {
    width: 35vw;
    height: 45vh;
    background-color: rgba(255, 255, 255, 0.33);
    box-shadow: 0px 8px 32px 0 rgba(0, 0, 255, 0.33);
    backdrop-filter: blur(3px);
    padding: 0px 16px 16px 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.verification-step {
    width: 30vw;
    min-width: 300px;
    min-height: 40vh;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Cambiado a 'space-between' para distribuir el contenido */
    align-items: center;
    margin: 20px auto;
    text-align: center;
}


h3 {
    margin-bottom: 10px;
}

.timer-container {
    margin: 20px 0;
}

.timer {
    font-size: 1.2rem;
    color: #007BFF;
}

.resend-button {
    padding: 10px 20px;
    font-size: 0.9rem;
    color: white;
    background-color: #CCCCCC;
    border: none;
    border-radius: 8px;
    margin-top: 10px;
    /* Espacio entre el botón de reenvío y el contador */
    cursor: not-allowed;
    /* Cursor de no permitido mientras está deshabilitado */
    transition: background-color 0.3s;
}

.button-verification {
    background-color: #404193;
    color: white;

}

.buttons-section {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}