.button-create-new-job {
    padding: 4px 20px;
    border-radius: 16px;
    background-color: #3F4193;
    color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 20px;
    height: 70px;
    width: 280px;
}