.container-login-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row-title-login-form {
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.text-title-login-form {
    font-size: 3vh;
    color: #404193
}