.row-content-general-auth {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.left-section {
    align-items: flex-start;
    background: linear-gradient(16deg, rgb(96, 82, 163), rgb(50, 45, 90));
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    padding-left: 80px;
    padding-right: 80px;
    overflow-y: auto;
}

.left-section img {
    width: 170px;
}

.left-section .logo-image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}

.left-section .title {
    font-size: 60px;
    color: white;
    margin-bottom: 30px;
    font-family: 'Mulish', sans-serif;
    font-weight: bold;
}

.description {
    font-size: 20px;
    font-family: 'Mulish', sans-serif;
    color: white;
    line-height: 1.5;
}

.right-section {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px 140px;

    justify-content: space-between;
    background-color: white;
    overflow-y: auto;
}

.right-section .title {
    color: var(--color-primary);
    font-size: 35px;
}

.container-buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    .right-section {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 0px 20px 0px 20px;
    }
}

/* Small devices (landscape phones, 576px and up) */
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .left-section {
        justify-content: center;
        padding-left: 0;
        height: auto;
        padding-top: 80px;
        padding-bottom: 80px;
        padding-left: 90px;
        padding-right: 50px;
    }

    .right-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
        box-sizing: border-box;
        padding: 20px 10px 20px 10px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .left-section {
        justify-content: center;
        padding-left: 0;
        height: auto;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 120px;
        padding-right: 120px;

    }

    .right-section {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-top: 40px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .left-section {
        height: auto;
        padding-top: 30px;
        padding-right: 20px;
        padding-bottom: 20px;
        padding-right: 50px;
    }

    .right-section {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 30px 0px 0px 0px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
    .left-section {
        align-items: flex-start;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        padding-left: 40px;
        padding-right: 40px;
        overflow-y: auto;
    }
}