.menu {
  height: "100%";
  width: "100%";
  border: "solid 1px";
  border-color: "#00000030";
  background-color: "black";
}

.container-logo-top {
  height: "15%";
  width: "100%";
  flex-direction: "column";
  display: "flex";
  justify-content: "center";
  align-items: "center";
}
