.content-pre-candidate-card {
    width: 98%;
    height: 90vh;
    border-radius: 10px;
    border: 1px solid;
    box-shadow: inset 7px 7px 15px #00000016;
    border-color: #00000020;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
    margin-top: 0.5%;
    position: relative;
}

.row-experience-pre-candidate-card {
    height: 78%;
    width: 100%;
}

.col-experience-pre-candidate-card {
    height: 90%;
    width: 100%;
}

.col-education-pre-candidate-card {
    height: 90%;
    width: 100%;
    border-color: #00000020;
}

.row-skills-pre-candidate-card {
    height: 12%;
}

.row-buttons {
    width: 100%;
    padding-bottom: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
}