.button-change-password {
    border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 20px;
    background-color: #404193;
    color: white
}

.form-item-button {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}