.registerForm {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
}

.form-row {
    display: flex;
    gap: 10px;
}

.form-row .ant-form-item {
    flex: 1;
    /* Para que los elementos se distribuyan equitativamente */
}

/* Estilos personalizados para el label del Form.Item */
.custom-form-item .ant-form-item-label>label {
    color: black !important;
    /* Aseguramos que el color del label sea blanco */
}

/* Estilos para los inputs */
.custom-input {
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #CCCCCC;
    padding: 7px;
}

/* Placeholder de los inputs */
.custom-input::placeholder {
    color: #CCCCCC;
}