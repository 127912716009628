.container-table-draw {
    width: 100%;
    height: full;
    margin-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px
}

.container-title {
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 'transparent'
}

.container-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
}

.container-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.slider-container {
    padding-left: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.autocomplete-skill {
    width: 100%;
    border: transparent;
    display: flex;
    align-items: center;
}

.column-name-skill {
    width: max-content;
    min-width: 200px;
}

.column-rating-skill {
    width: 150px;
    min-width: 140px;
}

.column-buttons-skills {
    width: 100px;
}

@media (max-width: 1020px) {
    .container-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
}