.container-dev {

    width: 100%;
    padding: 20px
}

.modal-title {
    margin-bottom: 20px;
    font-weight: bold;
}

.modal-description {
    font-size: 16px;
    line-height: 1.5;
}