.closeBtn {
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    color: white;
    background-color: #D12525;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: azure;
}

.closeBtn:hover {
    background-color: #a71919;
}

.text-label {
    color: #404193;
    font-size: 14px;
    font-family: Muli;
    margin-left: 12px;
}

.container-profile {
    border: 1px solid;
    border-color: #00000016;
    border-radius: 20px;
    height: 100%;
    width: 100%;
    overflow: auto;
    box-shadow: 7px 7px 15px #00000016;

}

.container-div-avatar {
    width: 98%;
    height: 98%;
    border-radius: 1rem;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cacaca,
}

.container-avatar {
    width: 50vh;
    height: 50vh;
}

.avatar-img {
    height: 100%;
    width: 100%;
}

.container-div-buttons {
    width: 100%;
    padding-top: 5px;
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1vw;
    border-top: 1px solid #c0c0c0;
}

.download-buttons {
    height: 30px;
    background-color: #404193;
    border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}