.button {
   background:pink,
   
}

input:focus{
        outline: none;
    }


.ant-modal-title{
    color:#129BD4
}
.ant-modal-close-x{
    display: block;
    width: 25px;
    height: 25px;
    font-size: 15px;
    font-style: inherit;
    line-height: 25px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    background-color: rgb(209, 37, 37);
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    color: white;
    margin-Top: 5px;
    margin-right: 5px;
}

.containerMdl{
    background-color: #129BD4;
    min-height:100%;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    position: relative;
    padding: 3px 16px;
    overflow-wrap: break-word;
}

.ant-modal-wrap {
    position: fixed;
    top: -80px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
}

.ant-carousel .slick-dots li.slick-active button{
    background: #129BD4;
    opacity: 1;
}

.ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    box-sizing: content-box;
    width: 16px;
    height: 3px;
    margin: 0 2px;
    margin-right: 3px;
    margin-left: 3px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s;
    background: #129BD480;
}

.ant-btn-primary{
    background:#129BD4;
    border-radius: 10%;
    box-shadow: 2px 3px #44444460;
   
}

.containerTable{
    border: 1px solid #ccc;
    border-radius: 3px;
}

.btnWorkplace{
    /* flexDirection:"row", display:"flex", justifyContent:"center", background:"transparent", borderColor:"transparent" */
    flex-direction: row;
    display: flex;
    justify-content: center;
    background: transparent;
    border-color: transparent;
}

.btnWorkplace:hover{
    background: #129ad425;
    border-radius: 5%;
}

.btnAdd{
    background:#129BD4;
    border-radius: 10%;
    box-shadow: 2px 3px #44444460;
    color: white;
    width: 100;
    padding-left: 30px;
    padding-right: 30px;
}

.ant-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color:#129BD440 ;
    border-radius: 2px;
    transition: background-color 0.3s;
}
.ant-carousel .slick-prev::before {
    content: '';
}
.ant-carousel .slick-next::before {
    content: '';
}

.ant-carousel .slick-prev, .ant-carousel .slick-next {
    position: absolute;
    top: 1.5%;
    display: flex;
    width: 25px;
    height: 100%;
    border: 0;
    outline: none;
    cursor: pointer;
    background: #129BD430;
    justify-content: center;
    align-items: center;
}
.ant-modal-body {
    padding: 30px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
}

.ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transition: all 0.3s;
}
