.title-section {
    font-size: 26px;
    color: #129bd4;
    font-weight: bold;
}

.text-information-preview {
    color: black;
    font-family: Muli;
    font-size: 15px;
    line-height: 1.5;
}

.div-container-last-section-preview-information {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.row-body-informations {
    width: 100%;
    flex: 1;
}

.col-content-information {
    width: 100%;
}