.container-header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.container-header-subtitle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-text {
    font-size: 25px;
    font-family: Muli;
    text-align: center;
}

.container-modal {
    height: 70vh;
    width: 100%;
    overflow-y: auto;
}


.container-inputs {
    width: 100%;
}

.row-container-inputs {
    width: 100%;
}

.container-text-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 20px;

}

.text-field-name-input {
    font-size: 14px;
    color: black;
    font-family: Muli;
    padding-left: 10px;
}

.input-fields {
    width: 100%;
    border-radius: 16px;

}

.container-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    height: auto;
}

.button-next-section {
    width: auto;
    height: auto;
    padding: 4px 20px;
    border-radius: 16px;
    background-color: #3F4193;
    color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 20px;
}

.text-information {
    color: black;
    font-family: Muli;
}