.contenedor::-webkit-scrollbar {
  width: 7px;
  background: transparent;
}

.contenedor::-webkit-scrollbar-thumb {
 
  background: #414193b2;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #272777;
}


.contenedor::-webkit-scrollbar-track {
 
  background: #4141933d;

}
.contenedor::-webkit-scrollbar-track {
 
  background: transparent;

}
